.contentWrapper > div {
  border-bottom: 1px solid #f1f3f3;
  padding: 1rem 0;
}

.contentWrapper > div:last-child {
  border-bottom: none;
}

.content {
  font-size: 14px;
  color: #888a8d;
  line-height: 1.75rem;
  flex: 1;
}

.content img {
  width: 100%;
  height: auto;
}

.content iframe {
  padding: 1rem 0;
}
