.daybookReportList {
  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex: 0 0 200px;
  }

  .okhati-list-rowcell {
    &:last-child {
      margin-right: 20px;
    }
  }
}
