.createHeader {
  padding: 4px 8px;
  border-radius: 5px;
  margin-right: 16px;
  font-size: 1rem;
  font-weight: 400;
  color: #959595;
  cursor: pointer;
}

.createHeader:hover {
  background: #e8e8e8;
  color: #292929;
}

.createHeader__active {
  color: #292929;
  background: #e8e8e8;
  font-weight: 500;
  cursor: initial;
}

.bookingCreateEdit {
  width: 500px;
}

.bookingInfoContainer {
  min-width: 450px;
}

@media only screen and (max-width: 768px) {
  .bookingCreateEdit {
    min-width: auto;
  }

  .bookingCreateEditBodyContainer {
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
  }
  .bookingInfoContainer {
    min-width: auto;
  }
  .bookingBodyContainer {
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
  }
}
