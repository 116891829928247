.serviceList {
  height: 100%;
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 50px !important;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
        &:nth-child(3) {
          flex-basis: 220px !important;
        }
        &:nth-child(4) {
          flex-basis: 200px !important;
        }
        &:nth-child(5) {
          flex-basis: 200px !important;
          padding-left: 65px;
        }
        &:last-child {
          flex-basis: 0 !important;
        }
        &:nth-child(6) {
          flex-basis: 200px !important;
        }
      }
    }

    .okhati-list-row {
      padding-right: 20px;
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 50px !important;
        }
        &:nth-child(2) {
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0 !important;
        }
        &:nth-child(3) {
          flex-basis: 200px !important;
        }
        &:nth-child(4) {
          flex-basis: 200px !important;
        }

        &:nth-child(5),
        &:last-child {
          flex-basis: 200px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .serviceList {
    .smallHeaderContainer {
      padding-top: 4px;
    }
  }
}
