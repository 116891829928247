.billItemsList {
  .okhati-list-headercell:nth-child(1),
  .okhati-list-rowcell:nth-child(1) {
    flex: 1 1 500px !important;
    white-space: nowrap;
    overflow: hidden;
  }

  div {
    div {
      border: none;
    }
  }
}
