.imnciList {
  .selected {
    border: 1px solid #009654;
    border-radius: 9999px;
    padding: 0 7px;
    background-color: #009654;
    color: white;
    display: inline-block;
  }
  .formattedValue {
    white-space: pre;
  }
}
