.handleBookingRemarks {
  padding: 16px;
  border: 1px solid #908f8f;
  box-shadow: 0px 0px 3px 3px #c7c7c754;
}

.buttonMarkHandled {
  margin-left: 8px !important;
}

.buttonCancel {
  margin-left: 32px !important;
}

.handledRemarksText {
  width: 100%;
  min-height: 42px;
}