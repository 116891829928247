.labRecordList {
  .okhati-list-container {
    height: calc(100% - 40px) !important;
    .okhati-list-header {
      .okhati-list-headercell {
        &:not(:last-child) {
          flex-grow: 1;
          margin-right: 6px;
        }
        &:first-child {
          flex-basis: 100px;
          flex-grow: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          flex-grow: 2;
          flex-basis: 100px;
        }

        &:nth-child(4) {
          flex-basis: 120px;
          margin-right: 20px;
        }

        &:nth-child(5) {
          flex-basis: 120px;
        }

        &:nth-child(6) {
          flex-basis: 150px;
        }
      }
    }
  }
}

.labRecordList {
  .okhati-list-container {
    .okhati-list-row {
      padding-right: 20px;
    }
    .okhati-list-rowcell {
      flex-grow: 1;
      margin-right: 6px;
      &:first-child {
        flex-basis: 100px;
        flex-grow: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        flex-grow: 2;
        flex-basis: 100px;
      }

      &:nth-child(4) {
        flex-basis: 120px;
        margin-right: 20px;
      }

      &:nth-child(5) {
        flex-basis: 120px;
      }

      &:nth-child(6) {
        flex-basis: 150px;
      }
    }
  }
}

.labRecordList.withMultiSelect {
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 52px !important;
          flex-grow: initial !important;
        }
        &:nth-child(2) {
          flex: 0 1 100px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          flex-shrink: 0;
        }
        &:last-child {
          flex-grow: 0;
          flex-basis: 20px;
        }
      }
    }
    .okhati-list-row {
      .okhati-list-rowcell {
        overflow: hidden;
        &:nth-child(2) {
          flex: 0 1 100px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          flex-shrink: 0;
        }
      }
    }
  }
}

.labRecordList.withMultiSelect {
  .okhati-list-container {
    .okhati-list-rowcell {
      &:first-child {
        flex-basis: 52px !important;
        flex-grow: initial !important;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .labRecordList {
    width: 100vw;
  }

  .labRecordList {
    .okhati-list-container {
      .okhati-list-header {
        .okhati-list-headercell {
          &:nth-child(1) {
            flex-basis: 100px;
            margin-left: 8px;
          }

          &:nth-child(2) {
            flex-basis: 160px;
            flex-grow: 1;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .labRecordList {
    .okhati-list-container {
      .okhati-list-rowcell {
        &:nth-child(1) {
          flex-basis: 100px;
          margin-left: 8px;
        }

        &:nth-child(2) {
          flex-basis: 160px;
          flex-grow: 1;
          margin-left: 8px;
        }
      }
    }
  }
}

.labNavigatePage {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
  justify-content: flex-end;
  width: calc(100vw - 50px);
  position: fixed;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 960px) {
  .labNavigatePage {
    width: 100%;
  }
}
