.ipdList {
  margin-top: 16px;
  height: calc(100vh - 110px);

  .okhati-list-container {
    .okhati-list-header {
      gap: 10px;

      .okhati-list-headercell {
        flex-basis: 250px;

        &:first-child {
          flex-basis: 150px;
          flex-grow: 0;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      gap: 10px;

      .okhati-list-rowcell {
        flex-basis: 250px;

        &:first-child {
          flex-basis: 150px;
          flex-grow: 0;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
  }
}

.ipdClientBills {
  .okhati-list-headercell:nth-child(1),
  .okhati-list-rowcell:nth-child(1) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex-basis: 200px !important;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex: 0 0 200px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4) {
    flex: 0 0 150px;
    justify-content: center;
  }

  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex: 0 0 100px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:nth-child(5) {
    margin-right: 15px;
  }
}
