.stockActions {
  background: #f1f1f1;
  width: calc(100% - 90px);
  box-shadow: 1px 1px 6px 0px #00000038;
}

@media (max-width: 960px) {
  .stockActions {
    width: calc(100%);
  }
}

.stockActionBtn {
  margin-left: 16px !important;
}

.discountInputInput {
  text-align: right;
  padding: 8px 10px !important;
  width: 70px !important;
}

.nrsReadInputRoot {
  margin: 0px !important;
}

.nrsReadInput {
  padding: 0px 8px !important;
  max-width: 110px !important;
  color: #454545 !important;
}

.nrsReadInput::before {
  border-bottom-style: none;
}

.rowTableCellRoot {
  padding: 8px 8px !important;
}

.headerCell.headerCell-item {
  flex-grow: 1;
}

.rowCell.rowCell-item {
  flex-grow: 1;
  flex-basis: initial !important;
}

.invoice {
  padding: 9.5px 8px !important;
  background: white !important;
}

.rowInputRoot {
  text-align: right;
  padding: 10px 8px !important;
  background: white !important;
  font-size: 0.875em !important;
}

/*Stock Row*/
.stockRow:hover {
  background: #00965523;
}

.productInputRoot {
  margin: 0px !important;
}

.productInput {
  font-size: 14px !important;
  background: white;
}

/* stock Items*/
.rowCell {
  margin-top: 4px;
}
.rowCell-itemType {
  flex-basis: 100px !important;
}

.headerCell-itemType {
  margin-left: 10px;
  flex-basis: 150px !important;
}

.rowCell-expiryDate {
  flex-basis: 80px !important;
}

.headerCell-expiryDate {
  flex-basis: 150px !important;
}

.rowCell-quantity {
  flex-basis: 70px !important;
}

.headerCell-quantity {
  flex-basis: 84px !important;
}

.headerCell-cc {
  flex-basis: 85px !important;
}

.rowCell-cc {
  flex-basis: 85px !important;
}

.rowCell-unit {
  flex-basis: 70px !important;
  width: 5px;
}

.headerCell-unit {
  flex-basis: 65px !important;
}

.headerCell-item {
  flex-grow: 1 !important;
}
.rowCell-batchId {
  flex-basis: 125px !important;
}

.headerCell-batchId {
  flex-basis: 125px !important;
}

.rowCell-grossTotal {
  flex-basis: 85px !important;
}

.headerCell-grossTotal {
  flex-basis: 85px !important;
}

.rowCell-rate {
  flex-basis: 85px !important;
}

.headerCell-rate {
  flex-basis: 85px !important;
}

.rowCell-disPct {
  flex-basis: 80px !important;
}

.headerCell-disPct {
  flex-basis: 80px !important;
}

.rowCell-vatPct {
  flex-basis: 80px !important;
}

.headerCell-vatPct {
  flex-basis: 80px !important;
}

.alertRoot {
  position: absolute;
  z-index: 5;
  margin-top: -68px;
  margin-left: -44px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1024px) {
  .headerCell-quantity {
    flex-basis: 70px !important;
  }
  .headerCell-cc {
    flex-basis: 45px !important;
  }
  .headerCell-unit {
    flex-basis: 70px !important;
  }
  .headerCell-rate {
    flex-basis: 65px !important;
  }
  .headerCell-price {
    flex-basis: 70px !important;
  }
  .headerCell-disPct {
    flex-basis: 60px !important;
  }
  .headerCell-vatPct {
    flex-basis: 70px !important;
  }
}
