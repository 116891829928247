.icon {
  height: 1rem !important;
  width: 1rem !important;
}

.reminderIcon {
  float: left;
}

.bookingType {
  color: #ffffff;
  background: #929292;
  padding: 1px 2px;
  border-radius: 3px;
}