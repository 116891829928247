.timepicker_root > div {
  border: none !important;
}

.root {
  height: 100%;
}

.alert:hover {
  opacity: 0.8;
}

.resourceCentrePage {
  height: calc(100vh - 130px);
}

.createButton {
  float: left;
  margin-left: 20px !important;
  margin-top: 10px !important;
}

@media screen and (min-width: 768px) {
  .resourceCentreDetails {
    margin-left: 25px;
    height: 150px;
  }

  .tabs {
    padding-top: 50px;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .resourceCentreDetails {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .tabs {
    padding-top: 10px;
    height: 100%;
  }
}

.listContainer {
  height: calc(100% + 30px);
}

.logo {
  height: 64px;
  border-radius: 50%;
}

.aboutSection {
  width: 400px;
  overflow-wrap: break-word;
}

.noMarginRight {
  margin-right: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.optPadding {
  padding: 0 0.5rem 0 auto !important;
}

.colorStyles {
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.adminForm,
.serviceProviderForm {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.banner {
  object-fit: cover;
  max-width: 100%;
}

@media only screen and (max-width: 960px) {
  .boxHeight {
    height: calc(100vh - 125px) !important;
    overflow-y: scroll !important;
  }
}

.settings_list_row {
  padding: 15px;
  min-width: 300px;
  width: 100%;
}

.settings_list_row:hover,
.settings_list_row_active {
  cursor: pointer;
  background: #00965526;
}
