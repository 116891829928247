.clientList {
  overflow-y: hidden;
  .eventIcon {
    svg {
      width: 0.7rem;
      height: 0.7rem;
    }
  }

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:not(:nth-last-child(-n + 3)) {
          margin-right: 12px;
        }

        &:first-child {
          flex-grow: 1;
        }

        &:nth-child(2) {
          flex-basis: 135px;
          flex-grow: initial;
        }

        &:nth-child(3) {
          flex-basis: 100px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          flex-basis: 200px;
        }
        &:nth-child(6) {
          flex-basis: 110px;
        }
      }
    }
    .okhati-list-rows {
      gap: 0.5rem;
      .ReactVirtualized__Grid.ReactVirtualized__List {
        scrollbar-gutter: stable;
      }
    }
    .okhati-list-row {
      padding-right: 20px;
      .okhati-list-rowcell {
        &:not(:nth-last-child(-n + 2)) {
          margin-right: 12px;
        }
        &:first-child {
          flex-grow: 1;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          flex-basis: 135px;
          flex-grow: initial;
        }

        &:nth-child(3) {
          flex-basis: 100px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          flex-basis: 200px;
        }
        &:nth-child(6) {
          flex-basis: 110px;
        }
      }
    }
  }
}
.navigatePage {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
  justify-content: flex-end;
  width: calc(100vw - 50px);
  position: fixed;
  bottom: 0;
  right: 0;
}
