.billLogo {
  height: 1cm;
}

.displayContents {
  display: contents;
  width: 0;
  height: 0;
}

.blockContent {
  page-break-inside: avoid;
}

.grow1 {
  flex: 0.7;
}

.flex1 {
  flex: 1;
}

.flex3 {
  flex: 3;
}

.borderTopBlack1 {
  border-top: 1px solid #c2c2c2;
}

.borderBotBlack1 {
  border-bottom: 1px solid #e2e2e2;
}

.billFooterPos {
  position: fixed;
  bottom: 0;
}

.billFooter,
.billFooterOffset {
  height: 2cm;
}

.actionBtn {
  padding-left: 8px;
}

.actionBtn:hover {
  text-decoration: none !important;
  cursor: pointer;
}

.actionBtnLabel:hover {
  font-weight: 500;
}
