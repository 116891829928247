.okhatiVirtualSelect {
  width: 300px;
  position: relative;

  &__inputContainer {
    width: inherit;
    height: 40px;
    border-radius: 3px;
    display: flex;
    border: 1px solid #95989A;

    &-active {
      border: 1px solid #009654;
    }

    &-highlight {
      border: 1px solid #009654;
      box-shadow: 1px 1px 12px 0 rgba(0, 180, 194, 0.25);
    }

    &-error {
      border: 1px solid #eb5b3f;
    }
  }

  &__searchIcon {
    flex-basis: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position-x: 8px;
    background-position-y: 13px;
  }

  &__input {
    flex-grow: 1;

    input {
      height: 100%;
      border: none;
      border-radius: 3px;
      box-sizing: border-box;
      width: 100%;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      line-height: 2.29;
      text-align: left;
      padding-left: 8px;
      padding-right: 24px;
      color: #3B3C3C;

      &::placeholder {
        color: #889492;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  &__dropIcon {
    flex-basis: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #e1e2e1;
      cursor: pointer;
    }
  }

  &__menuContainer {
    position: absolute;
    width: inherit;
    z-index: 100;
    background: #EEF2EF;
    box-shadow: 1px 4px 5px #888888;
    border-radius: 2px;
  }

  &__menuEmptyMessage {
    font-size: 14px;
    padding: 16px 6px;
    padding-left: 8px;
    color: #889492;
    font-weight: 500;
  }

  &__menuItems {
    overflow-y: auto;
  }

  &__menuItem {
    height: 40px;
    font-size: 14px;
    padding: 8px 6px;
    padding-left: 8px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #3B3C3C;

    &:hover {
      background: #99c2b0;
    }

    &__inactive {
      opacity: 0.4;
      cursor: no-drop;
    }
  }

  &__menuAction {
    border-top: 1px solid #e0e6e7;
    font-weight: 600;
    color: #009654;
    font-size: 14px;
    cursor: pointer;
    padding: 8px 6px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__clearIcon {
    height: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 240px;
    cursor: pointer;
  }
}