.templateForm {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.keywordSelect {
  border-radius: 16px;
  border: solid 1px #2f2f2f;
  padding: 4px 8px 4px 8px;
  font-size: smaller;
  cursor: pointer;
  margin: 0px 4px 4px 4px;
  flex-wrap: wrap;
}

.root {
  height: 100%;
}

.noWrap {
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
}
