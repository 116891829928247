.lab_details_container {
  margin-top: 16px;
  width: 100%;
  padding: 8px;
  .lab_details_headers_wrapper {
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 4px;
    .lab_details_header_cell {
      font-weight: 600;
      &:first-child {
        padding-left: 8px;
        flex-grow: 1;
      }
      &:nth-child(2) {
        flex-basis: 140px;
        text-align: center;
      }
      &:nth-child(3) {
        flex-basis: 100px;
        padding-left: 4px;
      }
      &:nth-child(4) {
        flex-basis: 140px;
      }
      &:nth-child(5) {
        flex-basis: 100px;
      }
    }
  }
  .details_row_container {
    padding-top: 8px;
    padding-bottom: 8px;
    .details_row_wrapper {
      display: flex;
      align-items: center;
      .details_row_cell {
        &:first-child {
          padding-left: 8px;
          flex-grow: 1;
          flex: 1;
        }
        &:nth-child(2) {
          flex-basis: 140px;
          text-align: center;
        }
        &:nth-child(3) {
          flex-basis: 100px;
          padding-left: 4px;
        }
        &:nth-child(4) {
          flex-basis: 140px;
        }
        &:nth-child(5) {
          flex-basis: 100px;
        }
        &:nth-child(6) {
          flex-basis: 50px;
        }
      }
    }
  }
}
