.okahti-billEditor {
  margin-top: 4px;
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none !important;
  }

  .rowDeleteButton {
    color: rgba(211, 211, 211, 0.28);
    cursor: pointer;
  }

  .billRow:hover {
    .rowDeleteButton {
      color: initial;
    }
  }

  .billRow {
    &:hover {
      .rowDeleteButton {
        color: initial;
      }
    }
  }

  .productAutocompleteSolo {
    .MuiAutocomplete-inputRoot {
      background: white;

      input {
        font-size: 14px;
        padding-left: 8px !important;
        background: white;
      }
    }
  }
}
