.mainIntraBox {
  display: flex;
  justify-content: space-around;
}

.subIntraBoxContainer {
  display: flex;
}

.contentIntraBox {
  height: 50px;
  display: flex;
  align-items: center;
}

.subIntraBox:first-child {
  border-right: 1px solid black;
}
