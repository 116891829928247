.calendar {
  width: 224px;
}

.weekdays {
  display: block;
  overflow: auto;
}

.weekday {
  width: 32px;
  float: left;
  text-align: center;
  margin-bottom: 8px;
  font-size: 10px;
  font-weight: 600;
  color: #292929;
}

.row {
  display: block;
  overflow: auto;
}

.day {
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 40px;
  font-weight: 700;
  color: #5d5d5d;
  float: left;
  /* border: 1px solid rgb(241, 241, 241); */
  /* border-radius: 2px; */
  text-align: center;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day:hover {
  border: 1px solid green;
}

.days {
  overflow: hidden;
  scrollbar-width: none;
}

.weekend {
  color: red;
}

.dayMuted {
  opacity: 0.7;
}

.disabled {
  color: darkgray;
  opacity: 0.3;
}

.day.disabled:hover {
  border: none !important;
}

.switch {
  overflow: auto;
  width: 224px;
  padding: 10px 0;
  margin-bottom: 8px;
}

.btns {
  width: 25%;
  float: left;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
}

.activeSwitch {
  /* border-bottom: 2px solid rgb(46, 192, 236); */
}

.forwardBtn {
  text-align: right;
  font-weight: bold;
  padding: 0px 15px;
}

.backBtn {
  text-align: left;
  font-weight: bold;
  padding: 0px 8px;
}

.selectedDay {
  border-radius: 2px;
  background-color: #009654;
  color: white;
}

.highlightedDays {
  background-color: rgba(0, 150, 84, 0.31);
}

.today span {
  color: white;
  border-color: white;
  border: 2px solid #009654;
  background-color: #009654;
}
