@import "../../styles/colors.scss";

.labStatus {
  color: white !important;
  padding: 4px !important;

  &.sampletaken {
    background-color: #1ab86c;
  }

  &.ordered {
    background-color: #e39130;
  }

  &.resultready {
    background-color: $emerald;
  }

  &.canceled {
    background-color: red;
  }

  &.incompleteresult {
    background-color: #2d58aa;
  }

  &.pendingapproval {
    background-color: #dccc06;
  }

  &.dispatched {
    background-color: #006600;
  }
}
