.billingList {
  height: calc(100% - 45px) !important;

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:nth-child(5) {
          flex-basis: 200px;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          justify-content: flex-end;
          margin-right: 15px;
        }

        &:last-child {
          padding-left: 15px;
          justify-content: flex-start !important;
        }
      }
    }

    .okhati-list-rowcell {
      &:nth-child(5) {
        flex-basis: 200px;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        justify-content: flex-end;
        margin-right: 15px;
      }

      &:last-child {
        padding-left: 15px;
        justify-content: flex-start !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .billingList {
    .subHeadContainer {
      justify-content: flex-end;
    }
  }

  .billingList {
    .searchContainer {
      margin-right: 8px;
    }
  }
}

.billItemsShow {
  word-break: break-word;

  .okhati-list-container {
    .okhati-list-rowcell {
      &:nth-child(1) {
        flex-basis: 50%;
        flex-grow: 1;
      }

      &:nth-child(2) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      &:nth-child(3) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      &:nth-child(4) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      &:nth-child(5) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      height: 30px !important;
    }
  }
}

.billItemsShow {
  word-break: break-word;

  .okhati-list-container {
    .okhati-list-headercell {
      &:nth-child(1) {
        flex-basis: 50%;
        flex-grow: 1;
      }

      &:nth-child(2) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      &:nth-child(3) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      &:nth-child(4) {
        flex-basis: 150px;
        flex-grow: 1;
      }

      &:nth-child(5) {
        flex-basis: 150px;
        flex-grow: 1;
      }
    }
  }
}

.creditNotesShow {
  word-break: break-word;

  .okhati-list-container {
    .okhati-list-rowcell {
      height: 30px !important;
    }
  }
}
