.stockItems__container {
  height: 300px;
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 60px;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
        &:nth-child(3) {
          flex-basis: 80px;
        }
        &:nth-child(4) {
          flex-basis: 100px;
        }
        &:nth-child(5) {
          flex-basis: 100px;
        }
        &:nth-child(6) {
          flex-basis: 80px;
        }
        &:nth-child(7) {
          flex-basis: 80px;
        }
        &:nth-child(8) {
          flex-basis: 100px;
        }
        &:nth-child(9) {
          flex-basis: 100px;
        }
        &:nth-child(10) {
          flex-basis: 120px;
        }
        &:nth-child(11) {
          flex-basis: 100px;
        }
        &:last-child {
          flex-basis: 40px;
        }
      }
    }

    .okhati-list-row {
      align-items: center;
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 60px;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
        &:nth-child(3) {
          flex-basis: 80px;
        }
        &:nth-child(4) {
          flex-basis: 100px;
        }
        &:nth-child(5) {
          flex-basis: 100px;
        }
        &:nth-child(6) {
          flex-basis: 80px;
        }
        &:nth-child(7) {
          flex-basis: 80px;
        }
        &:nth-child(8) {
          flex-basis: 100px;
        }
        &:nth-child(9) {
          flex-basis: 100px;
        }
        &:nth-child(10) {
          flex-basis: 120px;
        }
        &:nth-child(11) {
          flex-basis: 100px;
        }
        &:last-child {
          flex-basis: 40px;
        }
      }
    }
  }
}
