.root {
  border-bottom: 1px solid lightgray;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.11);
}

.actionBtn {
  padding-left: 8px;
}

.actionBtn:hover {
  text-decoration: none !important;
  cursor: pointer;
}

.actionBtnLabel:hover {
  font-weight: 500;
}