.audienceList {
  overflow-y: hidden;
  height: calc(100vh - 60px);
  .okhati-list-container {
    .okhati-list-header {
      gap: 10px;
      .okhati-list-headercell {
        flex-basis: 200px;
        flex-grow: 1;
      }
    }

    .okhati-list-row {
      gap: 10px;
      .okhati-list-rowcell {
        flex-basis: 200px;
        flex-grow: 1;
        &:first-child,
        &:last-child {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
