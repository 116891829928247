.dateDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 315px;
  cursor: pointer;
}

.gotoToday {
  white-space: pre;
  margin-left: 10px;
  color: black;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.gotoToday:hover {
  color: black;
}
