@import "../../../styles/colors.scss";

.labStatus {
  color: white !important;
  padding: 4px !important;

  &.ordered {
    background-color: #e39130;
  }

  &.cancelled {
    background-color: red;
  }

  &.delivered {
    background-color: $emerald;
  }
}
