.medicalLabList {
  height: calc(100vh - 110px);
  .okhati-list-container {
    .okhati-list-header {
      gap: 10px;
      .okhati-list-headercell {
        flex-basis: 250px;
        &:first-child {
          flex-basis: 200px;
          flex-grow: 0;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      gap: 10px;
      .okhati-list-rowcell {
        flex-basis: 250px;

        &:first-child {
          flex-basis: 200px;
          flex-grow: 0;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
  }
}
