$border: 1px solid #5a607f;

@mixin hover {
  background-color: #00965526;
  cursor: pointer;
}

@mixin cell {
  flex-basis: 200px;
  text-align: left;
  align-items: center;
  &:first-child {
    flex-basis: 160px;
    flex-grow: initial;
    padding: 0px 20px 0 60px;
  }
  &:nth-child(2) {
    flex-grow: 1;
  }
  &:nth-child(3) {
    flex-basis: 200px;
  }
  &:nth-child(4) {
    flex-basis: 180px;
  }
  &:nth-child(5) {
    flex-basis: 180px;
  }
  &:nth-child(6) {
    flex-basis: 100px;
  }
  &:nth-child(7) {
    flex-basis: 100px;
  }
  &:nth-child(8) {
    flex-basis: 100px;
  }
}

.accounts-list-headers {
  box-shadow: 0 4px 5px -2px #8080806e;
  padding: 15px 0 15px 0;
  display: flex;
  overflow-y: hidden;
  scrollbar-gutter: stable;

  .headercell {
    @include cell;
  }
}

.accounts-list-secondary-name {
  padding: 10px 20px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
}

.accounting-groups-name {
  padding: 10px 0 10px 40px;
  font-weight: 600;
  font-size: 16px;
  border-top: $border;
  border-bottom: $border;
  &:hover {
    @include hover;
  }
}

.account-child-ledger {
  display: flex;
  padding: 10px 0;

  &:hover {
    @include hover;
  }
  .child {
    @include cell;
  }
}

.inputField {
  margin-top: 20px;
}

.displayContents {
  display: contents;
  width: 0;
  height: 0;
}
