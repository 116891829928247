/* Main Content */
@media (min-width: 960px) {
  .assessmentRoot {
    display: flex;
    width: 100%;
  }

  .historyContent {
    width: 30%;
  }

  .assessmentContent {
    width: 70%;
  }
}

.assessmentRightPanel {
  width: 100%;
  padding: 8px 16px;
  overflow-y: auto;
}

/* History */
.historyRoot {
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.clientHeader {
  padding: 0;
}

@media (max-width: 960px) {
  .assessmentRightPanel {
    padding: 0px;
  }

  .historyRoot {
    height: initial;
    overflow-y: auto;
  }

  .collapseHistory {
    display: none;
  }

  .assessmentRow {
    display: block !important;
    /* border: 1px solid black; */
    height: auto !important;
  }

  .assessmentLabel {
    width: 100% !important;
  }

  .assessmentField {
    width: 100% !important;
  }

  .assessmentContent {
    padding: 0 !important;
  }

  .formHeader {
    padding: 16px 16px !important;
  }

  .assessmentFormRoot {
    border-radius: 0 !important;
    margin: 0 !important;
  }

  .medicationLabel {
    display: none;
  }

  .medicationField {
    width: 100% !important;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.hiddenLarge {
  display: none;
}

@media (max-width: 700px) {
  .intraOralComp {
    width: 100vw;
    overflow-x: scroll;
    display: flex;
  }

  .hiddenSmall {
    display: none;
  }

  .hiddenLarge {
    display: block;
    margin-bottom: -8px;
  }
}

.assessmentRow {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 62px;
}

@media (min-width: 960px) {
  .titleArrow {
    display: none !important;
  }
}

.historyBlock {
  width: 100%;
}

/* Assessment */
.assessmentFormRoot {
  height: calc(100vh - 82px);
  overflow-y: auto;
  padding: 0 !important;
}

.formHeader {
  margin: 0;
  padding: 16px 32px;
  box-shadow: 0px 1px 5px 0px #00000017;
}

.formFooter {
  margin: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -1px 5px 0px #00000017;
}

.medicationRow {
  display: flex;
  align-items: center;
  padding: 8px;
}

.assessmentContent {
  width: 100%;
  padding: 24px 16px;
  height: calc(100% - 120px);
  overflow-y: auto;
}

.assessmentLabel {
  width: 15%;
}

.medicationLabel {
  width: 15%;
}

.assessmentField {
  width: 85%;
}

.medicationField {
  width: 85%;
}

.assessmentFieldContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonGroups {
  display: flex;
}

@media (max-width: 960px) {
  .assessmentFormRoot {
    height: calc(100vh - 182px);
    overflow-y: auto;
    padding: 0 !important;
  }

  .extraDetails {
    display: none;
  }

  .formFooter {
    padding: 16px 0px;
    box-shadow: 0px -1px 5px 0px #00000017;
  }
}
