.editIcon {
  display: none !important;
  height: 16px !important;
}

.contentEditable:hover .editIcon {
  display: block !important;
}

.editableArea {
  cursor: text;
}

.placeholder {
  color: #969696;
}

.actionIcons {
  margin-right: 8px;
  cursor: pointer;
}
