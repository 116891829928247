.proseMirrorMain {
  width: 100%;
}

.proseMirrorContainer {
  padding: 8px;
  border: 1px solid #ccc;
  transition: 0.1s;
  border-radius: 5px;
  background-color: #fff;
}

.proseMirror table {
  border-collapse: collapse;
}
.proseMirror tr {
  border: 1px solid grey !important;
}
.proseMirror td,
.proseMirror th {
  border: 1px solid grey !important;
  border-radius: 10px;
  padding: 2px 5px;
}

.proseMirror {
  width: 100%;
  outline: none !important;
  padding: 0;
}

.proseMirror > div > p {
  margin: 0px !important;
}

.proseMirror:focus {
  outline: none !important;
}

.proseMirror * {
  outline: none !important;
}

.proseMirrorMenu {
  display: flex;
  margin-bottom: 7px;
  padding-left: 2px;
}

.proseMirrorMenu > button {
  font-size: 14px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ccc;
}

.proseMirrorMenu > button.bold {
  font-weight: 700;
}

.proseMirrorMenu > button.italic {
  font-style: italic;
}

.proseMirrorMenu > button.link {
  font-style: normal;
}

.proseMirrorMenu > button.underline {
  text-decoration: underline;
}

.proseMirrorMenu > p {
  margin: 0;
  line-height: 1.3;
}

.keywordSelect {
  border-radius: 16px;
  border: solid 1px #2f2f2f;
  padding: 4px 8px 4px 8px;
  font-size: smaller;
  cursor: pointer;
  margin: 0px 4px 4px 4px;
  flex-wrap: wrap;
}
