.billLogo {
  height: 1cm;
}

.FooterPrintOkhatiLogo {
  width: 1.7cm;
  height: 0.55cm;
}

.displayContents {
  display: contents;
  width: 0;
  height: 0;
}

.blockContent {
  page-break-inside: avoid;
}

.bankPaperFontSize {
  font-size: 0.23cm;
}

.grow1 {
  flex: 0.5;
}

.grow2 {
  flex: 1;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 1.5;
}

.flex3 {
  flex: 3;
}

.borderTopBlack1 {
  border-top: 1px solid #c2c2c2;
}

.borderBotBlack1 {
  border-bottom: 1px solid #bbb;
}

.billFooterPos {
  position: fixed;
  bottom: 0;
}

.billFooter,
.billFooterOffset {
  height: 2cm;
}

.actionBtn {
  padding-left: 8px;
}

.actionBtn:hover {
  cursor: pointer;
}

.actionBtnLabel:hover {
  font-weight: 500;
}
