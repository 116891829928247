.calendarPage {
  display: flex;
  height: calc(100vh - 50px);
}

.sidePanel {
  flex-basis: 240px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .calendarPage {
    flex-direction: column;
  }
  .sidePanel {
    padding-top: 0;
    flex-basis: auto;
    margin-bottom: 16px;
  }
  .selectedDate {
    font-size: 0.8rem;
  }
}

.calendarpanel {
  flex-grow: 1;
  height: inherit;
  border-left: 1px solid lightgray;
  overflow-y: scroll;
}
