.filter {
  cursor: pointer;
  padding-bottom: 8px;
  margin-right: 16px !important;
  color: gray;
}

.filter:hover,
.filter.active {
  color: #009654;
}
.filter.active {
  font-weight: 900;
}

.boxHeight {
  height: calc(100vh - 125px);
  overflow-y: auto;
}

.addServiceProvider {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #009654;
  border-bottom: 2px solid #009654;
  cursor: pointer;
}
.addServiceProvider:hover {
  opacity: 0.8;
}
