.accountSettingsTitle {
  margin-top: 41px;
  margin-bottom: 32px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.tabContainerCustom.MuiTabs-root {
  min-height: 40px;
  height: 40px;
}

.tabContainerCustom .MuiTabs-flexContainer {
  align-items: center;
  height: 40px;
}

.tabContainerCustom .tabButton {
  text-transform: none;
  flex: none;
}

.accountTabs {
  box-shadow: none !important;
  border-bottom: 1px solid #00000033;
}
