$orangeColour: #eb5b3f;
$grayColour: #889492;

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: auto;
  font-family: "Open Sans", "Poppins", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: #292929;
  width: 85%;
}

div.Controlled.Input.FilesInputContainer {
  height: auto !important;

  .subLabel {
    display: block;
    position: relative;
    font-size: 11px;
    line-height: 13px;
    color: $grayColour;
    transition: color 0.5s ease;

    &.hasError {
      color: $orangeColour;
    }
  }

  .Controlled.Input {
    height: auto;
    padding: 4px 0px !important;
    width: 100%;

    .attachmentRow {
      padding: 8px 0 8px 8px;
      border-bottom: 1px solid lightgray;
      background-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &.successful {
        // background: url(../../../Static/images/notification_success.svg) no-repeat left center;
        background-size: 14px;
      }

      &.failed {
        // background: url(../../../Static/images/notification_warning.svg) no-repeat left center;
        background-size: 14px;

        a {
          color: $orangeColour;
        }

        .options {
          color: $orangeColour;
        }
      }

      &:first-child {
        > span > a {
          width: 350px;
        }
      }

      a {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      div.fileName {
        width: 100px;
        position: relative;
        white-space: nowrap;
        display: flex;
        justify-content: center !important;

        p.name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      div.options {
        margin-right: 8px;
      }

      span.deleteIcon {
        // background: url(../../../Static/images/cancel.svg) no-repeat left center;
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 5px;
        align-self: center;

        .delete {
          width: 16px;
          height: 16px;
          color: #db2323;
        }
      }

      .sideIcon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: -8px;
      }

      .success {
        color: #009654;
      }

      .failure {
        color: #db2323;
      }
    }
  }

  .fine-uploader-dropzone-container {
    height: auto !important;
    width: auto !important;
    border: none !important;
  }

  .react-fine-uploader-file-input-container {
    margin-top: 8px;
    cursor: pointer;

    span.ion-upload {
      // background: url(../../../Static/images/attachment.svg) no-repeat left center;
      padding-left: 24px;
      background-size: 14px;
    }
  }

  .react-fine-uploader-total-progress-bar-container {
    float: right;
    width: 200px;
    line-height: 13px;
    margin-top: 11px;
    border-radius: 5px;
    background: rgb(241, 241, 241);

    > .react-fine-uploader-total-progress-bar {
      height: 8px;
      border-radius: 5px;
    }
  }

  .Button {
    margin-top: 8px;
    cursor: default;
    font-weight: 400;
  }

  .ion-upload {
    // background: url(../../../Static/images/attachment.svg) no-repeat left center;
    padding-left: 24px;
    background-size: 14px;
  }
}
