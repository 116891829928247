.mobileUserList {
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 240px;
          flex-grow: 0;
        }

        &:nth-child(2) {
          flex-basis: 150px;
          flex-grow: 0;
        }

        &:nth-child(3) {
          flex-basis: 160px;
        }
      }
    }
  }
}

.mobileUserList {
  .okhati-list-container {
    .okhati-list-rowcell {
      &:first-child {
        flex-basis: 240px;
        flex-grow: 0;
      }

      &:nth-child(2) {
        flex-basis: 150px;
        flex-grow: 0;
      }

      &:nth-child(3) {
        flex-basis: 160px;
      }
    }
  }
}
