.nestedHeaderList {
  height: 100%;
  scrollbar-gutter: stable;
  position: relative;

  .nestedHeaderTable {
    border-collapse: collapse;
  }

  .tableRowHover {
    cursor: pointer;
  }

  .nestedHeaderTableCell {
    text-align: center;
    border: 1px solid #ccc;
  }

  .nestedHeaderTableHeaderCell {
    background: #f2f2f2;
  }
}

.emptyViewContainer {
  display: grid;
  place-items: center;

  .emptyListViewImage {
    height: 200px;
    width: 100%;
    background-size: 70%;
    background: url("../../../assets/images/emptyList.svg") no-repeat center bottom;
  }
}

.loadingViewContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%, -50%);
}
