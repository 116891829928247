.input {
  width: 100%;
}

.welcomeHeader {
  padding: 160px 24px 24px 24px;
  background-position-y: 0 !important;
  background: url('../../../../assets//images/welcome2.svg') no-repeat center;
}


.divider {
  margin-bottom: 24px !important;
}