.userGroupList {
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        margin-right: 10px;
        &:nth-child(1) {
          flex-basis: 200px;
          flex-grow: 0;
        }
        &:nth-child(2) {
          flex-basis: 240px;
          flex-grow: 0;
        }

        &:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
    .okhati-list-row {
      .okhati-list-rowcell {
        margin-right: 10px;
        &:nth-child(1) {
          flex-basis: 220px;
          flex-grow: 0;
        }
        &:nth-child(2) {
          flex-basis: 240px;
          flex-grow: 0;
        }

        &:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
  }
}
