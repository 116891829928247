.massLinkserviceList {
  height: 100%;
  padding: 16px;

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:nth-child(1) {
          flex-basis: 350px !important;
        }

        &:nh-child(2) {
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:nth-child(1) {
          flex-basis: 350px !important;
        }

        &:nh-child(2) {
          flex-grow: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .massLinkserviceList {
    .smallHeaderContainer {
      padding-top: 4px;
    }
  }
}
