.vouchersList {
  min-width: 900px;
  margin-top: 16px;
  height: calc(100vh - 60px);
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 150px;
          margin-right: -10px;
        }
        &:nth-child(2) {
          flex-basis: 130px;
        }
        &:nth-child(3) {
          flex-grow: 1;
        }
        &:nth-child(4) {
          padding-left: 24px;
          & > span {
            width: 100%;
            max-width: 120px;
            text-align: right;
          }
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 150px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: -10px;
        }
        &:nth-child(2) {
          flex-basis: 130px;
        }
        &:nth-child(3) {
          flex-grow: 1;
        }
        &:nth-child(4) {
          padding-left: 24px;
          & p {
            width: 100%;
            max-width: 120px;
            text-align: right;
          }
        }
        &:last-child {
          flex-grow: 100px;
        }
      }
    }
  }
}

.statusIndicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
