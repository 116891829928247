.calendarContainer {
  height: 100%;
}

.calendarHeader {
  height: 96px;
  box-shadow: 0 4px 5px -2px #8080806e;
}

.calendarColumns {
  height: calc(100% - 96px);
  overflow-y: scroll;
  position: relative;
}

.calScroll {
  overflow: scroll;
  visibility: hidden;
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
}

.calendarControls {
  height: 40px;
  padding: 0px 20px;
}

.dayHeaders {
  display: flex;
}

.dayHeader {
  text-align: center;
  width: calc(100% / 7);
  padding: 4px 16px;
}

.dayHeaderListView {
  width: calc(100% / 10);
}

.timeColumnHeader {
  width: 30px;
}

.todayColumnHeaderDate {
  display: inline-block;
  height: 32px;
  width: 32px;
  background: #009654;
  color: white;
  border-radius: 50%;
  padding: 0;
}

.weekendHeader {
  color: red;
}

.weekendHeader .todayColumnHeaderDate {
  color: white;
  background: red;
}

.calEvent {
  border: 1px solid lightgray;
  background: white;
  cursor: pointer;
  padding: 4px;
}

.calSchedule {
  background-color: rgba(255, 66, 246, 0.1);
  cursor: pointer;
}

.selectedDate {
  font-size: 1.2rem;
}

.headerActionsContainer {
  flex-basis: 240px;
}

@media only screen and (max-width: 768px) {
  .selectedDate {
    font-size: 0.8rem;
    display: flex;
    font-weight: 600;
    margin-left: 16px;
    margin-top: 8px;
  }

  .headerActionsContainer {
    flex-basis: 180px;
  }

  .calendarHeader {
    height: 120px;
  }

  .dayHeader {
    margin-top: 16px;
  }

  .calendarColumns {
    height: calc(100% - 120px);
  }
}

.fadeHightlight {
  animation: fadeHighlight 10s;
}

@keyframes fadeHighlight {
  0% {
    background-color: rgba(240, 230, 140, 0.6);
  }
  100% {
    background-color: none;
  }
}

.followupIndicator {
  position: absolute;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  top: -6px;
  right: -6px;
}
.remarksIndicator {
  position: absolute;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  top: -4px;
  right: 4px;
  margin-left: -12px;
  margin-top: -12px;
}

.isPaidIndicator {
  position: absolute;
  background-color: #009654;
  border-radius: 50%;
  top: -8px;
  left: -6px;
}

.publicBookingSchedule {
  cursor: pointer;
  background-color: rgba(0, 128, 0, 0.1);
}

.publicScheduleOCHours {
  cursor: pointer;
  background-color: rgba(216, 250, 8, 0.2);
}
