.feedList {
  word-break: break-word;
  .okhati-list-container {
    .okhati-list-headercell {
      &:nth-child(1) {
        flex-basis: 200px;
        flex-grow: 1;
      }
      &:nth-child(2) {
        flex-basis: 140px;
        flex-grow: 1;
      }
      &:nth-child(3) {
        flex-basis: 140px;
        flex-grow: 1;
      }
      &:nth-child(4) {
        flex-basis: 140px;
        flex-grow: 1;
      }
      &:nth-child(5) {
        flex-basis: 200px;
        flex-grow: 1;
      }
    }
  }
}

.feedList {
  word-break: break-word;
  .okhati-list-container {
    .okhati-list-row {
      align-items: center;
    }
    .okhati-list-rowcell {
      &:nth-child(1) {
        flex-basis: 200px;
        flex-grow: 1;
      }
      &:nth-child(2) {
        flex-basis: 140px;
        flex-grow: 1;
      }
      &:nth-child(3) {
        flex-basis: 140px;
        flex-grow: 1;
      }
      &:nth-child(4) {
        flex-basis: 140px;
        flex-grow: 1;
      }
      &:nth-child(5) {
        flex-basis: 200px;
        flex-grow: 1;
      }
      height: 30px !important;
    }
  }
}

@media screen and (max-width: 615px) {
  .feedList {
    .okhati-list-header {
      margin-top: 60px;
    }
  }

  .feedList {
    .subHeadContainer {
      justify-content: flex-end;
    }
  }

  .feedList {
    .searchContainer {
      margin-right: 0px;
    }
  }
}
