.profilePictureWrapper {
  margin-bottom: 21px;
  position: relative;
}

.profilePicture {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-right: 11px;
}

.editProfile {
  display: inline-block;
  font-weight: bold;
  position: absolute;
  top: 20px !important;
  cursor: pointer;
}

.userName {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
}

.textField {
  width: 400px;
  border-radius: 4px;
  background-color: #efefef;
  margin-bottom: 20px !important;
}

.textField input {
  padding: 12px 0 10px 16px;
}

.textField label {
  margin: 5px 0 0 16px;
}

.updateButton {
  margin-top: 40px !important;
}

.cancelButton {
  margin-top: 40px !important;
}

.profileImage {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}

.editButton {
  padding-top: 15px;
  padding-left: 10px;
}

.spSignatureImage {
  height: 120px;
  width: 220px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  object-fit: contain;
}
