.referrerTransactionList {
  height: 500px;
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-grow: 1;
        }
        &:nth-child(2) {
          flex-basis: 100px !important;
        }
        &:nth-child(3) {
          flex-basis: 130px !important;
        }
        &:nth-child(4) {
          flex-basis: 120px !important;
        }
        &:last-child {
          flex-basis: 80px !important;
          margin-right: 15px;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0px !important;
        }
        &:nth-child(2) {
          flex-basis: 100px !important;
        }
        &:nth-child(3) {
          flex-basis: 130px;
        }
        &:nth-child(4) {
          flex-basis: 120px;
        }
        &:last-child {
          flex-basis: 80px !important;
        }
      }
    }
  }
}

.referrerLedgersList {
  height: 500px;
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-grow: 2;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
        &:nth-child(3) {
          flex-grow: 1;
        }
        &:nth-child(4) {
          flex-grow: 1;
        }
        &:last-child {
          flex-grow: 1;
          margin-right: 15px;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-grow: 2;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
        &:nth-child(3) {
          flex-grow: 1;
        }
        &:nth-child(4) {
          flex-grow: 1;
        }
        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
}
