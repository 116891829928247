.okhati-list-headercell:first-child {
  margin-left: 20px;
}

.okhati-list-rowcell:first-child {
  margin-left: 20px;
}

.searchContainer {
  margin-right: 64px;
}

@media (max-width: 768px) {
  .searchContainer {
    margin-right: 0;
  }
}
