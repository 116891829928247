.campaignList {
  height: calc(100vh - 60px);
  overflow-y: hidden;
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child,
        &:nth-child(2) {
          flex-basis: 150px;
          flex-grow: 1;
        }

        &:nth-child(3) {
          flex-basis: 200px;
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 150px;
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:nth-child(2) {
          flex-grow: 1;
          flex-basis: 150px;
        }

        &:nth-child(3) {
          flex-basis: 200px;
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
}

.statusIndicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
