.node {
  transition: 0.2s ease;
  position: relative;
}

.container .node:hover {
  background-color: lightgrey;
}

.editIcon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  display: none;
}

.node:hover .editIcon {
  display: block;
}
