.ledgerStyle {
  .okhati-list-headercell:nth-child(1),
  .okhati-list-rowcell:nth-child(1) {
    flex-grow: 1 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex-basis: 200px !important;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex-basis: 300px;
  }
  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4),
  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex-basis: 200px;
  }
}
