.popoverContent {
  padding: 16px auto;
  width: 100vw;
  max-width: 500px;
}

.periodGroup {
  padding: 16px;
}

.calendarGroup {
  padding: 16px;
  border-top: 1px solid #d2d2d2;
}

.calendarGroupLabel {
  font-size: 16px !important;
  margin-bottom: 8px !important;
}

.calendarInputGroup {
  grid-template-columns: repeat(auto-fit, 120px);
  align-items: center;
  grid-gap: 12px;
  padding-top: 8px;
}

.MuiList-padding {
  padding: 0 0 10px 0 !important;
}

.selected {
  background-color: #009654 !important;
  color: #fff !important;
}

.specificPickerGridItem {
  align-content: center;
  align-items: center;
  display: flex;
  padding-right: 8px !important;
}

.specificPickerGridItem:nth-child(3) {
  padding-right: 0px !important;
}