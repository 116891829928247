.tests_edit_container {
  margin-top: 16px;
  width: 100%;
  overflow-y: auto;
  padding: 8px;
  .headers_wrapper {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    .header_cell {
      font-weight: 600;
      &:first-child {
        flex-basis: 350px;
        flex-grow: 1;
      }
      &:nth-child(2) {
        flex-basis: 140px;
      }
      &:nth-child(3) {
        flex-basis: 200px;
      }
      &:nth-child(4) {
        flex-basis: 200px;
      }
      &:nth-child(5) {
        flex-basis: 200px;
      }
    }
  }
  .row_container {
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid lightgray;
    .row_wrapper {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      .row_cell {
        &:first-child {
          flex-basis: 350px;
          flex-grow: 1;
        }
        &:nth-child(2) {
          flex-basis: 140px;
        }
        &:nth-child(3) {
          flex-basis: 200px;
        }
        &:nth-child(4) {
          flex-basis: 200px;
        }
        &:nth-child(5) {
          flex-basis: 200px;
        }
        &:nth-child(6) {
          flex-basis: 50px;
        }
      }
    }
  }
}

.container * {
  &:first-child {
    min-width: 30px;
  }
  &:nth-child(2) {
    background-color: "red";
    flex-basis: 500px;
  }
  &:nth-child(3) {
    flex-basis: 200px;
  }
  &:nth-child(4) {
    flex-basis: 200px;
  }
  &:nth-child(5) {
    flex-basis: 200px;
  }
  &:nth-child(6) {
    flex-basis: 150px;
  }
}

.panelFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding: 2rem 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.panelContent {
  padding: 1rem 2rem 0 2rem;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
