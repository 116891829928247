.labRoot {
  width: 100%;
  height: calc(100% - 50px);
}

.labHeader {
  padding: 16px 20px 8px;
}
@media screen and (max-width: 768px) {
  .labHeader {
    padding: 4px 8px 0;
  }
}

.labEntryHeader {
  margin-bottom: 32px;
  padding: 32px 32px;
}

.labEntryHeaderText {
  margin-bottom: 32px;
}

.additionalData table {
  border-collapse: collapse;
  min-width: 50%;
}

.additionalData tr {
  border: 1px solid grey;
}
.additionalData td,
.additionalData th {
  border: 1px solid grey;
  padding: 2px 5px;
}

.labEntryHeaderInformation {
  width: 70%;
}

.labEntryFooter {
  width: 100%;
}

.labFooterButtonContainer {
  display: flex;
  flex-basis: 280px;
  justify-content: space-evenly;
}

.labStickyFooter {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 100%;
  flex-basis: auto;
  position: fixed;
  bottom: 0;
  padding-right: 32px;
}

.filter {
  color: #808080;
  cursor: pointer;
}
.filterRoot {
  padding: 0 20px 20px;
}
@media screen and (max-width: 768px) {
  .filterRoot {
    padding: 0 8px 4px;
  }
}

.filter:hover,
.filter.active {
  color: #000;
}
.filter.active {
  font-weight: 600;
}

.statusFilter:hover,
.statusFilter.active {
  cursor: pointer;
  color: #000;
  border-bottom: 3px solid #000;
}

.appBarLabTabs {
  box-shadow: none !important;
}

.paginatedListViewHeight {
  height: calc(100vh - 168px);
}

.nonPaginatedListViewHeight {
  height: calc(100vh - 128px);
}

.labEntryContent {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
}

.tenWidthCent {
  width: 10%;
  text-align: center;
}

.twenWidthCent {
  width: 30%;
  text-align: center;
}

.fifthWidthCent {
  width: 50%;
}

.labEntry {
  height: calc(100vh - 100px);
  overflow-y: auto;
  justify-content: flex-end;
}

@media screen and (max-width: 960px) {
  .labStickyFooter {
    width: 100%;
    position: relative;
    bottom: auto;
  }

  .listViewHeight {
    height: calc(100vh - 220px);
  }

  .appBarLabTabs {
    width: 100vw !important;
  }

  .labEntryHeaderInformation {
    width: 100%;
  }

  .labEntryContent {
    width: 720px;
  }

  .tenWidthCent {
    width: 10%;
  }

  .twenWidthCent {
    width: 30%;
  }

  .fifthWidthCent {
    width: 50%;
  }
}

@media only screen and (max-width: 960px) {
  .boxHeight {
    height: calc(100vh - 145px) !important;
    overflow-y: scroll !important;
  }
}

.rowInputRoot {
  text-align: right;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom_file_input::before {
  content: "Upload Image";
  display: inline-block;
  outline: none;
  font-family: "Open Sans", "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;

  cursor: pointer;
}
.menu {
  max-width: 5%;
}

.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

#simple-menu {
  width: 100px;
  height: 100px;
}

.menu_button {
  width: 100px;
  height: 100px;
}

.imageCapture {
  height: 40px;
  width: 40px;
  border-radius: 40%;
}
