.clientBills {
  .okhati-list-container {
    .okhati-list-headercell {
      span {
        font-size: 0.7rem !important;
      }

      flex-basis: 80px !important;
      flex-grow: initial !important;
    }

    .okhati-list-headercell:first-child {
      flex-grow: 1 !important;
    }

    .okhati-list-headercell:last-child {
      flex-basis: 100px !important;
    }

    .okhati-list-headercell {
      &:nth-child(2) {
        flex-basis: 200px !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell:first-child {
      flex-grow: 1 !important;
    }

    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(2) {
        flex-basis: 200px !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell:last-child {
      flex-basis: 100px !important;
    }

    .okhati-list-row .okhati-list-rowcell {
      flex-basis: 80px !important;
      flex-grow: initial !important;
    }
  }
}

.clientLabs {
  .okhati-list-container {
    .okhati-list-headercell {
      span {
        font-size: 0.7rem !important;
      }
    }

    .okhati-list-headercell:first-child {
      flex-basis: 80px !important;
    }

    .okhati-list-headercell:last-child {
      flex-basis: 50px !important;
    }

    .okhati-list-headercell {
      &:nth-child(3) {
        flex-basis: 130px !important;
      }
    }

    .okhati-list-headercell {
      &:nth-child(2) {
        flex-grow: 1 !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell:first-child {
      flex-basis: 80px !important;
    }

    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(2) {
        flex-grow: 1 !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(3) {
        flex-basis: 130px !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell:last-child {
      flex-basis: 50px !important;
    }
    .okhati-list-row {
      .okhati-list-rowcell {
        span {
          font-size: 10px !important;
        }
      }
    }
  }
}

.clientLedger {
  .okhati-list-container {
    .okhati-list-headercell {
      span {
        font-size: 0.7rem !important;
      }
    }

    .okhati-list-headercell:first-child {
      flex-basis: 130px !important;
    }

    .okhati-list-headercell:last-child {
      flex-basis: 50px !important;
    }

    .okhati-list-headercell {
      &:nth-child(3) {
        flex-basis: 130px !important;
      }
    }

    .okhati-list-headercell {
      &:nth-child(5) {
        flex-basis: 100px !important;
        justify-content: flex-end;
        margin-right: 15px;
      }
    }
    .okhati-list-headercell {
      &:nth-child(6) {
        flex-basis: 100px !important;
        justify-content: flex-end;
        margin-right: 15px;
      }
    }

    .okhati-list-headercell {
      &:nth-child(2) {
        flex-basis: 130px !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell:first-child {
      flex-basis: 130px !important;
    }

    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(2) {
        flex-basis: 130px !important;
      }
    }

    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(3) {
        flex-basis: 130px !important;
      }
    }
    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(5) {
        flex-basis: 100px !important;
        justify-content: flex-end;
        margin-right: 15px;
      }
    }

    .okhati-list-row .okhati-list-rowcell {
      &:nth-child(6) {
        flex-basis: 100px !important;
        justify-content: flex-end;
        margin-right: 15px;
      }
    }

    .okhati-list-row .okhati-list-rowcell:last-child {
      flex-basis: 50px !important;
    }
    .okhati-list-row {
      .okhati-list-rowcell {
        span {
          font-size: 10px !important;
        }
      }
    }
  }
}
