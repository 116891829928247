.stockList {
  height: calc(100vh - 100px);
  .vListSearch {
    margin-right: 16px;
  }
  .subHeadContainer {
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      .searchContainer {
        padding-right: 1rem;
      }
    }
  }
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          margin-right: 10px;
          flex-basis: 200px;
          flex-grow: 1;
        }
        &:nth-child(2) {
          flex-basis: 130px;
          justify-content: flex-end;
          padding-right: 20px;
        }

        &:nth-last-child(2) {
          & > span {
            max-width: 130px;
            width: 100%;
            text-align: right;
            margin-right: 15px;
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          flex: 0 1 300px;
        }

        &:nth-last-child(3) {
          flex-basis: 100px;
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      padding-right: 20px;
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 200px;
          flex-grow: 1;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 10px;
          height: 100%;
        }
        &:nth-child(2) {
          flex-basis: 130px;
          justify-content: flex-end;
          padding-right: 20px;
        }
        &:last-child {
          & p {
            max-width: 130px;
            width: 100%;
            text-align: right;
            margin-right: 15px;
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          flex: 0 1 300px;
        }

        &:nth-last-child(2) {
          flex-basis: 100px;
          flex-grow: 1;
        }
      }
    }
  }
}

.navigatePage {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
  justify-content: flex-end;
  width: calc(100vw - 50px);
  position: fixed;
  bottom: 0;
  right: 0;
}
