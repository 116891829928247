* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.menuItem {
  width: 300px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 5px 15px;
}
.menuItem:hover {
  background-color: rgb(243, 243, 243);
}

.subItem {
  margin-left: 20px !important;
}

.title {
  margin-bottom: 20px;
  font-weight: 700;
}

.header {
  font-weight: 600;
  padding: 0 15px;
  width: 150px;
  text-align: center;
}

.mainHeader {
  width: 200px;
  text-align: center;
}

.subHeader {
  padding: 15px;
  width: 200px;
  font-weight: 600;
}

.headerContainer {
  width: 800px;
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}
