.root {
  background: #f1f1f1;
  width: calc(100% - 84px);
  box-shadow: 1px 1px 6px 0px #00000038;
}

@media (max-width: 960px) {
  .root {
    width: calc(100%);
  }
}

.feedActionBtn {
  margin-left: 16px !important;
}
