.success {
  background-color: #43a047 !important;
}
.error {
  background-color: #d32f2f !important;
}
.info {
  background-color: #1976d2 !important;
}
.warning {
  background-color: #ffa000 !important;
}
.icon {
  font-size: 20;
}
.iconVariant {
  opacity: 0.9;
}
.message {
  display: flex;
  align-items: center;
}