.modaltitle {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

.modalfooter {
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
}

.weekcalendarContainer {
  height: calc(100vh - 50px);
  overflow: hidden;
  width: 100%;
}

.weekcalendarHeaders {
  display: flex;
  box-shadow: 0 4px 5px -2px #8080806e;
  height: 75px;
  padding-top: 10px;
  border-top: 1px solid lightgray;
}

.weekcalendarHeader {
  flex-basis: 200px;
  color: blue !important;
}

.headerEmptyslot {
  flex-basis: 120px;
}

.weekcalendarGridcell {
  height: 50px;
  border-top: 1px solid #80808021;
  box-sizing: border-box;
}

.Hour {
  border-top: 1px solid #80808080;
}

.weekcalendarDayColumns {
  display: flex;
  height: calc(100% - 75px);
  overflow-y: scroll;
}

.weekcalendarDayColumn {
  border-right: 1px solid #80808080;
  height: 2400px;
  flex-basis: 200px;
}

.weekcalendarTimecolumn {
  flex-basis: 120px;
  height: 2400px;
  border-right: 1px solid gray;
}

.weekcalendarEvent {
  height: 50px;
  width: 100%;
  position: relative;
  border-radius: 2px;
  background: lightgray;
}

.weekcalendarEvents {
  height: 0px;
  width: inherit;
}

.weekcalendarClock {
  width: 100%;
  position: relative;
  height: 0;
}

.weekcalendarClockBar {
  height: 2px;
  background: red;
  width: 100%;
}

.weekcalendarActionBar {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (max-width: 768px) {
  .weekcalendarActionBar {
    box-shadow: 0 4px 5px -2px #8080806e;
  }
}

.weekcalendarActionBarLeft {
  flex-grow: 1;
  padding-left: 16px;
}

@media (max-width: 768px) {
  .weekcalendarActionBarLeft {
    padding-right: 16px;
  }
}

.serviceProviderSelect {
  flex-grow: 1;
}

.createSlotButton {
  flex-grow: 1;
}

.weekcalendarActionBarRight {
  text-align: right;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .weekcalendarActionBarRight {
    padding: 16px;
  }
}

.colorRed {
  color: red !important;
}

.event {
  cursor: pointer;
  padding: 4px 4px;
  border-radius: 3px;
  padding: 4px;
}

.reserved {
  background: #5187de20;
}

.booked {
  background: #bfbfbf20;
}

.handled {
  background: #00965524;
}

.referred {
  background: #0074dc24;
}

.bookedPill {
  border: 1px solid #bbbbbb;
}

.slotPill {
  border: dashed 1px #bbbbbb;
}

.highlightedBookedPill {
  box-shadow: 0 0px 5px 2px #8080806e;
  border: 1px solid #0a5d91;
}

.okhatiLoader {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 100;
}

.alignVerticallyMiddle {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
  width: 1rem !important;
  color: #585858;
}

.root {
  position: 'relative';
}

.paper {
  position: 'absolute';
  top: 36;
  right: 0;
  left: 0;
  z-index: 9999;
}

.query {
  font-size: 14px;
  color: #959595;
  padding: '8px 16px';
}