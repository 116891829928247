@import "../../../styles/colors.scss";

.billStatus {
  color: white !important;

  &.cancelled {
    background-color: $tomato;
  }

  &.billed {
    background-color: $emerald;
  }

  &.draft {
    background-color: #a5a5a5;
  }

  &.proformaDraft {
    background-color: #97aedb;
  }
}
