.scheduleCreateEdit {
  min-width: 500px;
}

.createEditBody {
  min-width: 230px;
}

@media only screen and (max-width: 768px) {
  .scheduleCreateEdit {
    min-width: auto;
  }
  .createEditBody {
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
  }
}
