.root{
  display: flex;
}

.paper{
  max-width: 1000px;
  margin: auto;
 }

.input {
  width: 100%;
}

.logo {
  height: 35px;
}