.bigAvatar {
  height: 60px !important;
  width: 60px !important;
}

.input {
  width: 100%;
}

.box {
  margin-left: 0px !important;
}

.icon {
  font-size: inherit !important;
  color: #585858;
  margin-right: 16px;
}