@media (max-width: 959px) {
  .drawer {
    background-color: white !important;
  }

  .menuItem {
    flex-direction: row !important;
  }

  .menuItemIcon {
    margin-right: 24px;
    height: 20px;
    width: 20px;
    /* Equivalent of color #292929 */
    filter: hue-rotate(359deg) saturate(0) brightness(0.16);
  }

  .label {
    color: #292929;
  }

  .drawerWrapper {
    flex-basis: 400px !important;
  }

  .topBarIcon {
    color: white;
  }

  .topButtonLabel {
    color: white;
  }

  .list {
    width: 220px;
  }

  .clinicLogo {
    display: none;
  }

  .clinicName {
    width: 80px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 768px) {
  .clinicName {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 960px) {
  .drawer {
    width: 84px;
  }

  .menuItemIcon {
    color: black;
    filter: invert();
  }

  .label {
    color: white;
  }

  .clinicSpecific {
    margin-left: 55px !important;
  }

  .clinicName {
    width: auto;
  }
}

.topBar {
  height: 40px;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #00000030;
  display: flex;
  align-items: center;
  z-index: 2;
}

.drawerWrapper {
  flex-basis: 84px !important;
}

.drawer {
  border-right: none !important;
}

.topButton {
  float: right;
  text-transform: none !important;
  margin-right: 8px !important;
}

.clinicSpecific {
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: none !important;
}

.clinicLogo {
  height: 32px;
  object-fit: contain;
}

.clinicName {
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  line-height: 32px;
}

.topButtonDropdown {
  float: right;
  padding: 6px !important;
  margin-right: 8px !important;
}

.menuPaper {
  z-index: 5000;
}

.logo {
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
}

.logoimg {
  height: 32px;
  margin: auto;
}

.logotext {
  height: 20px;
}

.logoMobileMenu {
  max-width: 120px;
  margin-left: 16px;
  margin-top: 24px;
  margin-bottom: 36px;
}

.menuItem {
  flex-direction: column;
  padding-bottom: 4px !important;
}

.menuItemIcon {
  text-align: center;
  font-size: 20px !important;
}

.label {
  font-size: 0.6rem !important;
  font-weight: 600 !important;
  margin-top: 2px !important;
  text-align: center;
}

.bottom {
  flex-basis: 70px;
  text-align: center;
}

.selected,
.selected:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: -2px 0 5px 0 rgba(0, 0, 0, 0.25);
}

.smallProfileImage {
  height: 25px !important;
  width: 25px !important;
}

.notificationBox {
  min-width: 400px;
}

.notificationItems {
  max-height: 400px;
  overflow-y: auto;
}

.notiMenuItem {
  position: relative;
  display: flex;
  cursor: pointer !important;
  border-bottom: 1px solid #e6e6e6 !important;
}

.notificationGroupTitle {
  background-color: #efefef;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .notiMenuItem {
    width: calc(100vw - 25px) !important;
    white-space: initial !important;
  }

  .clinicSpecific {
    display: none;
  }
}

.notiMenuItem:hover {
  cursor: inherit;
}

.notiMenuItem:hover .markAsReadButton {
  display: block;
}

.noHover:hover {
  background-color: transparent !important;
  cursor: inherit;
}

.markIcon {
  border: none;
  padding: 0;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #009654;
  border-radius: 50%;
  cursor: pointer;
}

.markAsReadButton {
  position: absolute;
  right: 3rem;
  display: none;
}

.markIconActive {
  background-color: #c5c5c5;
}

.notiMenuItemRead {
  opacity: 0.6 !important;
}

.helpIcon {
  color: #757575;
  cursor: pointer;
}

.iconWhite {
  color: #fff;
}
