$border: 1px solid #5a607f;

@mixin hover {
  background-color: #00965526;
  cursor: pointer;
}

@mixin cell {
  flex-basis: 200px;
  align-items: center;
}

@mixin ledger-cell {
  padding: 12px;
  display: flex;
  align-items: center;
  border-bottom: $border;
}

.primary-ledger-cell {
  @include ledger-cell;
}
.secondary-ledger-cell {
  padding-left: 55px !important;
  @include ledger-cell;
}

.generalLedgerReportStyles {
  height: calc(100vh - 265px) !important;
  overflow-y: hidden !important;
}

.accounts-list-primary {
  &-name {
    padding-left: 36px;
    font-size: 16px;
    font-weight: bold;
    flex: 1;
  }
  &-balance {
    font-size: 16px;
    font-weight: 500;
    flex-basis: 200px;
    display: flex;
    justify-content: flex-end;
    padding-right: 1.625rem;
  }
}
.account-list-secondary-name {
  font-size: 16px;
  font-weight: 500;
}

.accounting-group {
  &-cell {
    @include ledger-cell;
    padding: 1rem 1.625rem 1rem 1rem;
    &:hover {
      @include hover;
    }
  }
  &-name {
    font-size: 16px;
    font-weight: 400;
    flex: 1;
    padding-left: 60px;
  }
  &-balance {
    font-size: 16px;
    font-weight: 400;
    flex-basis: 160px;
    text-align: right;
    &:last-child {
      padding-right: 8px;
    }
  }
}

.account-child-ledger {
  display: flex;
  padding: 10px 0;

  &:hover {
    @include hover;
  }
  .child {
    @include cell;
  }
}
.child-ledger {
  &-cell {
    @include ledger-cell;
    &:hover {
      @include hover;
    }
  }
  &-body {
    font-weight: 400;
    font-size: 16px;
    flex-basis: 200px;
    &:nth-child(n + 3) {
      text-align: right;
    }
  }
}

.total-amount {
  &-cell {
    background: rgba(90, 96, 127, 0.1);
    @include ledger-cell;
  }
  &-value {
    flex-basis: 160px;
    font-size: 16px;
    font-weight: 600;
    &:not(:first-child) {
      text-align: right;
    }
    &:last-child {
      padding-right: 8px;
    }
  }
}

.flex-1 {
  flex: 1 !important;
}
.center-text {
  text-align: center;
}
.cell {
  @include ledger-cell;
}
.text-center {
  text-align: center;
}

.body {
  &-cell {
    @include ledger-cell;
    &:hover {
      @include hover;
    }
  }
  &-field {
    flex-basis: 200px;
    font-weight: 400;
  }
}

.trialBalanceReport {
  overflow: auto;
  .accounts-list-headers {
    box-shadow: 0 4px 5px -2px #8080806e;
    padding: 1rem 0 1rem 1rem;
    display: flex;
    align-items: flex-start;

    .headercell {
      display: flex;
      flex-basis: 160px !important;
      &:first-child {
        padding: 0 1rem 0 0;
        justify-content: flex-end;
        flex: 0 1 160px;
        margin-right: 0;
      }

      &:nth-child(2) {
        justify-content: flex-start;
        flex-grow: 1;
        margin: 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        display: flex;
        justify-content: flex-end;
      }

      &:nth-last-child(2) {
        padding-right: 8px;
      }

      &.additionalColumns {
        flex-basis: 160px;
        justify-content: flex-end;

        &:nth-child(2) {
          justify-content: flex-start;
        }
      }
    }
  }
  & .child-sub-ledger-cell {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #5a607f;
    margin-right: 0;

    & .child-ledger-cell-ledgerId {
      flex: 0 1 160px;
      justify-content: flex-end;
      padding-right: 1rem;
    }

    .child-ledger-body:nth-child(2) {
      flex-grow: 1;
      flex-basis: 160px;
    }

    & .child-ledger-body:nth-child(3) {
      display: flex;
      justify-content: flex-end;
      flex-basis: 160px;
    }
    & .child-ledger-body:not(:nth-child(2)) {
      display: flex;
      justify-content: flex-end;
      flex-basis: 160px;
    }
  }

  & .child-ledger-cell {
    padding: 16px 25px 16px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #5a607f;
    margin-right: 0;

    & .child-ledger-cell-ledgerId {
      flex: 0 1 160px;
      justify-content: flex-end;
      padding-right: 1rem;
    }

    .child-ledger-body:nth-child(2) {
      flex-grow: 1;
      flex-basis: 160px;
    }

    & .child-ledger-body:last-child {
      padding-right: 8px;
    }

    & .child-ledger-body:not(:nth-child(2)) {
      display: flex;
      justify-content: flex-end;
      flex-basis: 160px;
    }
  }
}

.bSheetPLossReport {
  overflow: auto;
  .accounts-list-headers {
    box-shadow: 0 4px 5px -2px #8080806e;
    padding: 1rem 0 1rem 1rem;
    display: flex;
    align-items: flex-start;

    .headercell {
      display: flex;
      flex-basis: 160px !important;
      justify-content: flex-end;
      &:first-child {
        justify-content: flex-start;
        padding: 0 0 0 2.25rem;
        flex: 1 1 160px;
        margin-right: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        flex-grow: 0;
      }

      &:nth-last-child(2) {
        padding-right: 8px;
      }
    }
  }
}

.dayBookReport {
  overflow: auto;
  .accounts-list-headers {
    box-shadow: 0 4px 5px -2px #8080806e;
    padding: 1rem 0 1rem 1rem;
    display: flex;
    align-items: flex-start;

    & .headercell {
      display: flex;
      flex-basis: 200px !important;
      justify-content: flex-start;

      &:first-child {
        padding-left: 2.25rem;
      }
      &:nth-child(2) {
        flex-grow: 0;
      }

      &:nth-child(3) {
        flex-grow: 1;
      }
      &:nth-child(4) {
        justify-content: flex-end;
        flex-basis: 250px;
        padding-right: 8px;
      }
    }
  }

  & .body-cell {
    padding: 1rem 0 1rem 1rem;
    .body-field {
      display: flex;
      flex-basis: 200px !important;
      justify-content: flex-start;

      &:first-child {
        padding-left: 2.25rem;
        padding-right: 1.25rem;
      }
      &:nth-child(2) {
        flex-grow: 0;
      }

      &:nth-child(3) {
        flex-grow: 1;
      }
      &:nth-child(4) {
        justify-content: flex-end;
        flex-basis: 250px;
        padding-right: 8px;
      }
    }
  }
}

.reportListStyle {
  .okhati-list-container {
    height: calc(100vh - 190px);
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-grow: 1;
          width: 100%;
          overflow: hidden;
          flex-basis: 300px;
          text-overflow: ellipsis;
        }
        &:nth-child(8) {
          padding-right: 6px;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          justify-content: flex-end;
        }
      }
    }

    .okhati-list-row:not(.okhati-list-segment-sumary) {
      padding-right: 20px;
    }
    .okhati-list-rowcell {
      &:first-child {
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        flex-basis: 300px;
        text-overflow: ellipsis;
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        justify-content: flex-end;
      }
      &:nth-child(8) {
        padding-right: 6px;
      }
    }
  }
}

.customerVendorReportListStyle {
  min-width: 1100px;
  .okhati-list-container {
    height: calc(100vh - 210px);
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-grow: 1;
          width: 100%;
          overflow: hidden;
          flex-basis: 300px;
          text-overflow: ellipsis;
        }
        &:nth-child(8) {
          padding-right: 8px;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          justify-content: flex-end;
        }
      }
    }
    .okhati-list-row:not(.okhati-list-segment-sumary) {
      padding-right: 20px;
    }
    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-grow: 1;
          width: 100%;
          overflow: hidden;
          flex-basis: 300px;
          text-overflow: ellipsis;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          justify-content: flex-end;
        }
        &:nth-child(8) {
          padding-right: 7px;
        }
      }
    }
  }
}
