.inputResetButton {
  cursor: pointer;
}

.pointerNone {
  pointer-events: none !important;
}

/* diagnosis*/

.App {
  font-family: sans-serif;
}

.ButtonField {
  padding: 17px 12px !important;
  margin-top: 15px !important;
}

.textSearch {
  margin-right: 5px !important;
}

.btnMargin {
  margin: 4px !important;
  min-width: 40px !important;
  background-color: white !important;
  border: 1px solid #808080 !important;
}

.closeBtn {
  top: 8px !important;
  color: #9e9e9e !important;
  right: 8px !important;
  position: absolute !important;
}

.okBtn {
  margin-top: 5px !important;
  color: white !important;
  background-color: #b1afaf !important;
  pointer-events: none;
}

.successBtn {
  margin-top: 5px !important;
  color: white !important;
  background-color: #088c3c !important;
}

.clearBtn {
  margin-right: 70px !important;
}

.txtCntr {
  text-align: center !important;
}

.A {
  border: 1px solid gray !important;
  min-height: 45px !important;
}

.css-f91fgu {
  background-color: #ffffff !important;
  font-weight: 600 !important;
  color: #292727 !important;
}

.icdBtn {
  padding: 3px 12px !important;
  min-width: none !important;
}

.nodeRef {
  width: 6% !important;
  margin-top: 3px !important;
}

.mrtp6 {
  margin-top: 6px !important;
}

/*slate*/
.slate {
  background: #fff;
  padding: 1rem;
}

.drawingBoard {
  overflow: auto;
  margin: 0px !important;
  padding: 0px !important;
  border-top: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
}

.backGrndColor {
  background-color: white !important;
}

.txtAlgnEnd {
  text-align: end;
}

.mrgTp7 {
  margin-top: 5px !important;
}

.dgTitle {
  padding: 5px !important;
}

/*medication*/
.dropDown {
  margin-top: 2px !important;
  text-align: end !important;
  margin-bottom: 15px !important;
}

.actionBackGroundColor {
  background-color: #dfffe5 !important;
}

.cancelButtonColor {
  color: #009654 !important;
}

.mt10 {
  margin-top: 16px !important;
}

.mealBackgroundColor {
  background-color: #cecece !important;
}

.daysWeek {
  margin-top: 40px !important;
}

.btnPadding {
  padding: 8px !important;
}

.padTop {
  padding-top: 1rem !important;
}

#days {
  text-align: center !important;
}

/*affect others*/
/* .MuiTextField-root {
    width: 31% !important;
  } */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*mian*/

.App {
  font-family: sans-serif;
  text-align: center;
}

.succesBtn {
  color: white !important;
  background-color: #009654 !important;
}

.btnUnderLine {
  text-decoration: underline !important;
  padding: 0px !important;
}

.addMoreMrgn {
  margin-top: 64px !important;
}

.mt24 {
  margin-top: 30px !important;
}

.printFont {
  font-weight: 100 !important;
}

.btnCollapseItem {
  padding: 0.5rem 0 !important;
}
