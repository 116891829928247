.criteriaBox {
  border: 1px solid #2f2f2f;
  border-radius: 16px;
  margin-right: 12px;
  padding: 4px 12px 4px 16px;
  position: relative;
  margin-bottom: 10px;

  .cancelBtn {
    position: absolute;
    right: -6px;
    top: -6px;
    font-size: 0.9rem;
    color: #ce0000;
    cursor: pointer;
  }
}

.andContainer {
  //   :last-child::after {
  //     content: 'and';
  //   }
  //   //   &:not(:last-child)::before {
  //   //     content: 'and';
  //   //   }
}

.audienceForm {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.root {
  height: 100%;
}

.rightArrowIcon {
  width: 100% !important;
  text-align: end;
}

.rightSide {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.noWrap {
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
}

.txtAlgnEnd {
  text-align: "end";
}

.Fullwdt {
  width: 100%;
}

.mrgnTop {
  margin-top: 25px;
}

.mrgnBtm {
  margin-bottom: 20px;
}

.cursPointer {
  cursor: pointer;
}

.icn {
  text-align: end;
  margin-top: 10px;
}

.btnStyle {
  justify-content: flex-end;
}

.addOption {
  border-radius: 3px;
  //   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #707070;
  padding: 5px;
  cursor: pointer;
  width: 220px;
}
