.labTestGroupList {
  height: 100%;
  width: 100%;
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-grow: 1;
        }
      }
    }
    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-grow: 1;
        }
      }
    }
  }
}
