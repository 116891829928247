.root {
  width: 500px;
}

@media (max-width: 768px) {
  .root {
    width: inherit !important;
    height: 100vh;
  }
}

.bigAvatar {
  height: 45px !important;
  width: 45px !important;
  margin-right: 20px;
  border: 2px solid #8080801f;
}

.serviceProvider {
  display: flex;
  margin-bottom: 16px !important;
}

.input {
  width: 100%;
}

.box {
  margin-left: 0px !important;
}

.iconBox {
  align-items: center;
  justify-content: center;
  display: flex;
}

.icon {
  height: 1rem !important;
  width: 1rem !important;
  color: #585858;
  margin-right: 16px;
}

.bookingContent {
  padding: 0 24px 24px 24px !important;
}

.mrgnTop {
  margin-top: 15px !important
}

.sndryText {
  font-size: 12px !important
}

.primaryText {
  font-size: 14px !important
}

.drpDwnPadding {
  padding-top: 0px !important;
  padding-bottom: 0px !important
}

.clientInfoRoot {
  width: 100%;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.clearIconContainer {
  text-align: right;
}

.clearIcon {
  padding: 0 !important;
}

.followUpContainer {
  margin-top: 16px;
  margin-bottom: 16px;
}

.followUpRemark {
  width: 100%;
  margin-left: 8px !important;
}