/* Main Content */
@media (min-width: 960px) {
  .assessmentRoot {
    display: flex;
    width: 100%;
  }

  .historyContent {
    width: 30%;
  }

  .assessmentContent {
    width: 70%;
  }
}

.assessmentRightPanel {
  width: 100%;
  padding-left: 8px;
  overflow-y: auto;
}

/* History */
.historyRoot {
  height: calc(100vh - 142px);
  overflow-y: auto;
}

.clientHeader {
  padding: 0;
}

@media (max-width: 960px) {
  .historyRoot {
    height: initial;
    overflow-y: auto;
  }

  .collapseHistory {
    display: none;
  }

  .assessmentRow {
    display: block !important;
    /* border: 1px solid black; */
    height: auto !important;
  }

  .assessmentLabel {
    width: 100% !important;
  }

  .assessmentField {
    width: 100% !important;
  }

  .assessmentContent {
    padding: 0 !important;
  }

  .formHeader {
    padding: 16px 16px !important;
  }

  .assessmentFormRoot {
    border-radius: 0 !important;
    margin: 0 !important;
  }

  .medicationLabel {
    display: none;
  }

  .medicationField {
    width: 100% !important;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.hiddenLarge {
  display: none;
}

@media (max-width: 700px) {
  .intraOralComp {
    width: 100vw;
    overflow-x: scroll;
    display: flex;
  }

  .hiddenSmall {
    display: none;
  }

  .hiddenLarge {
    display: block;
    margin-bottom: -8px;
  }
}

.assessmentRow {
  display: flex;
  align-items: flex-start;
  padding-bottom: 8px;
}

@media (min-width: 960px) {
  .titleArrow {
    display: none !important;
  }
}

.historyBlock {
  width: 100%;
}

/* Assessment */
.assessmentFormRoot {
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 0 !important;
}

.formHeader {
  margin: 0;
  padding: 16px 32px;
  box-shadow: 0px 1px 5px 0px #00000017;
}

.formFooter {
  margin: 0;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -1px 5px 0 #00000017;
  position: fixed;
  right: 0;
  left: 84px;
  bottom: 0;
  background-color: #fff;
}

.medicationRow {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
}

.assessmentContent {
  width: 100%;
  padding: 24px 16px;
  height: calc(100% - 130px);
  overflow-y: auto;
}

.assessmentLabel {
  width: 15%;
}

.medicationLabel {
  width: 15%;
}

.assessmentField {
  width: 100%;
  margin-left: 2px;
}

.medicationField {
  width: 85%;
  margin-left: 2px;
}

.assessmentFieldContent {
  align-items: center;
}

.buttonGroups {
  display: flex;
}

@media (max-width: 960px) {
  .assessmentFormRoot {
    height: calc(100vh - 182px);
    overflow-y: auto;
    padding: 0 !important;
  }

  .formFooter {
    left: 0;
    box-shadow: 0px -1px 5px 0px #00000017;
  }
}

.toothBox {
  cursor: pointer;
}

.toothBox:hover {
  border: 2px solid green;
}

.toothBoxActive {
  border: 4px solid green;
}

.legendContainer {
  border: 1px solid black;
}

.legendButton {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid lightgray;
  background-color: white;
}

.legendButtonMuted {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid lightgray;
  background-color: rgb(236, 233, 233);
}

.legendActive {
  background-color: rgb(58, 162, 58);
}

.legendButton:hover {
  background-color: lightgreen;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
