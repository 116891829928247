.root {
  height: 100%;
}

.rightArrowIcon {
  width: 100% !important;
  text-align: end;
}

.rightSide {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.txtAlgnEnd {
  text-align: 'end';
}

.Fullwdt {
  width: 100%;
}

.mrgnTop {
  margin-top: 25px;
}

.mrgnBtm {
  margin-bottom: 20px;
}

.cursPointer {
  cursor: pointer;
}

.icn {
  text-align: end;
  margin-top: 10px;
}

.btnStyle {
  justify-content: flex-end;
}

.clientName {
  text-decoration: underline;
  cursor: pointer;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clientInfoModalRoot {
  min-width: 550px;
  padding: 32px !important;
}

@media (max-width: 768px) {
  .clientInfoModalRoot {
    min-width: 300px;
    padding: '8px' !important;
  }
}

.clientForm {
  height: calc(100vh - 100px);
  overflow-y: auto;
}
