.billActions {
  width: calc(100% - 67.2px);
}

@media (max-width: 960px) {
  .billActions {
    width: calc(100%);
  }
}

.billActionBtn {
  margin-left: 16px !important;
}

.discountInputInput {
  text-align: right;
  padding: 8px 10px !important;
  width: 70px !important;
}

.discountBillInput {
  text-align: right;
  padding: 8px 10px !important;
  width: 110px !important;
}

.nrsInput {
  text-align: right;
  padding: 8px 10px !important;
  width: 140px;
}

.nrsReadInputRoot {
  margin: 0px !important;
  color: inherit;
}

.nrsReadInput {
  padding: 0px 8px !important;
  max-width: 85px !important;
  color: #454545 !important;
  text-align: right;
}

.nrsReadInput::before {
  border-bottom-style: none;
}

.rowTableCellRoot {
  padding: 8px 8px !important;
}

.headerCell.headerCell-description {
  flex-grow: 1;
  flex-basis: initial !important;
}

.rowCell.rowCell-description {
  flex-grow: 1;
  flex-basis: initial !important;
}

.rowCell.rowCell-description-mobile {
  flex-basis: 100% !important;
}

.rowInputRoot {
  text-align: right;
  padding: 9.5px 8px !important;
  background: white !important;
  font-size: 0.875em !important;
}

/*Bill Row*/
.billRow {
  gap: 5px;
}

.billRow:hover {
  background: #00965523;
}

.productInputRoot {
  margin: 0px !important;
  border: "none";
}

.productInput {
  font-size: 14px !important;
  background: white;
}

/* bill Items*/

.rowCell-delivered {
  flex-basis: 60px !important;
}

.headerCell-delivered {
  flex-basis: 60px !important;
}

.rowCell-quantity {
  flex-basis: 80px !important;
}

.headerCell-quantity {
  flex-basis: 80px !important;
}

.rowCell-department {
  flex-basis: 60px !important;
}

.headerCell-department {
  margin-right: 10px;
  flex-basis: 60px !important;
}

.rowCell-unit {
  flex-basis: 100px !important;
}

.headerCell-unit {
  flex-basis: 100px !important;
}

.rowCell-serviceProvider {
  flex-basis: 180px !important;
}

.headerCell-serviceProvider {
  flex-basis: 180px !important;
}

.rowCell-perUnit {
  flex-basis: 100px !important;
}

.headerCell-perUnit {
  flex-basis: 100px !important;
}

.headerCell-vatPct {
  flex-basis: 70px !important;
}

.rowCell-vatPct {
  flex-basis: 75px !important;
}

.rowCell-grossTotal {
  flex-basis: 100px !important;
}

.headerCell-grossTotal {
  flex-basis: 100px !important;
}

.headerCell-info {
  flex-basis: 25px !important;
}

/* bill settings */
.billSettingsRoot {
  padding: 10px;
  min-width: 360px;
  max-width: 500px;
  margin-top: 10px;
}
.batchInfo {
  width: 420px;
  position: absolute;
  z-index: 5;
  transform: translate(-50%, -110%);
}

.batchContent {
  padding: 8px;
  max-height: 200px;
  overflow: auto;
}

.batchItemHover:hover {
  background-color: rgba(0, 150, 85, 0.14);
  cursor: pointer;
}

.alertRoot {
  position: absolute;
  z-index: 5;
  margin-top: -52px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.mobileRoot {
  left: 0;
}

.paymentMethod {
  background-color: white !important;
}

.billLabel {
  font-size: 16px;
  font-weight: 500;
}
