.reportCardRoot {
  width: 350px;
  height: 150px;
  padding: 16px;
  display: inline-block;
  margin-right: 16px;
}

.reportCardRoot:hover {
  cursor: pointer;
  box-shadow: 0px 0px 7px 3px #00000052;
}

.reportCardChartIcon {
  height: 100px !important;
  width: 100px !important;
}

.listRoot {
  width: calc(100vw-100px);
}

.listStyles {
  height: calc(100vh - 330px) !important;
  overflow-y: hidden !important;
}

.commissionListStyles {
  height: calc(100vh - 240px) !important;
  overflow-y: hidden !important;
}

.salesSummaryListStyles {
  height: calc(100vh - 280px) !important;
  overflow: hidden !important;
}

.dueReportListStyles {
  height: calc(100vh - 412px) !important;
  overflow-y: hidden !important;
}

.receiptReportListStyles {
  height: calc(100vh - 300px) !important;
  overflow-y: hidden !important;
}

.billPaymentListStyles {
  height: calc(100vh - 380px) !important;
  overflow-y: hidden !important;
}

.labReportListStyles {
  height: calc(100vh - 280px) !important;
  overflow-y: hidden !important;
}

.salesByServicesReportListStyles {
  height: calc(100vh - 360px) !important;
  overflow-y: hidden !important;
}

.stockCashFlowReportListStyles {
  height: calc(100vh - 300px) !important;
  overflow-y: hidden !important;
}

@media screen and (max-width: 960px) {
  .listStyles {
    height: calc(100vh - 380px) !important;
    overflow-y: hidden !important;
  }
}
