.modalRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
}

.modalContent {
  background-color: #fff;
  margin: 1rem !important;
  border-radius: 0.25rem;
}

.modalHeader {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem !important;
}

.modalBody {
  box-sizing: border-box;
  padding: 1rem 1.5rem !important;
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
}

.valueText {
  color: rgb(83, 83, 83);
}

.iconText {
  min-width: 1.25rem !important;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3rem;
  padding-right: 1.5rem;
  background-color: rgba(160, 160, 160, 0.15);
}

.fullWidth {
  width: 100%;
}

.listItemGroup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.wordBreak {
  word-break: break-all;
}

.flex1 {
  flex: 1;
}

.remarkText {
  font-size: 0.75rem;
}

.padBot1 {
  padding-bottom: 1rem !important;
}

.assImgContent {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #bfbfbf;
}

.imgLabel {
  padding: 0.5rem;
}

.assessmentImg {
  width: 100%;
  max-width: 300px;
  height: 30vh;
  object-fit: contain;
}

.noBorder {
  border: none;
}
