.assessmentQR {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

.qrCodeContainer {
  display: flex;
  width: 60%;
}

.qrCode {
  padding-top: 8px;
  padding-right: 16px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subMainRow {
  display: flex;
  justify-content: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
}

.powByOkhati {
  display: flex;
  justify-content: space-between;
}

.okhatiLogo {
  height: 35px;
  width: 35px;
}

@media screen and (max-width: 720px) {
  .assessmentQR {
    width: 100%;
    margin: 0;
    padding: 8px;
  }

  .footerContent {
    display: block;
  }

  .qrCodeContainer {
    display: flex;
    width: 100%;
  }

  .qrCode {
    padding-top: 8px;
  }

  .powByOkhati {
    display: flex;
    width: 120px;
  }

  .okhatiLogo {
    height: 35px;
    width: 35px;
    margin-top: 4px;
  }

  .intraOralContentQR {
    padding-left: 24px;
  }

  .medicationQR {
    padding-left: 32px;
  }
}
