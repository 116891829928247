.root {
  position: fixed;
  display: inline-block;
  right: 0;
  bottom: 0;
  z-index: 1202;
  transform: translateY(calc(100% + 3rem));
  transition: transform 0.3s ease-in-out;
}

.content iframe {
  margin-right: 0.25rem;
  border: none;
  border-radius: 0.25rem;
  min-height: 480px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.show {
  transform: translateY(0);
}

.closeButton {
  position: absolute;
  z-index: 3;
  bottom: 0.25rem;
  right: 0.25rem;
  background-color: #009654;
}

.closeButton:hover {
  background-color: #009654;
}
