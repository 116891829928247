.header {
  font-size: 20px;
  color: gray;
}
@media screen and (max-width: 600px) {
  .header {
    font-size: 16px;
  }
}
.stockRoot {
  margin-top: 16px;
}

.filter {
  cursor: pointer;
  padding-bottom: 8px;
  margin-right: 16px;
  color: gray;
}
@media screen and (max-width: 600px) {
  .filter {
    margin-right: 8px;
  }
}

.filter:hover,
.filter.active {
  color: #009654;
}
.filter.active {
  font-weight: 600;
}

.boxHeight {
  height: calc(100vh - 125px);
  overflow-y: auto;
}
