.logo {
  height: 35px;
  width: 128px;
}

.loginButton {
  display: flex;
  justify-content: flex-end;
}

.fbLoginButton {
  margin-top: 16px !important;
}

.svgIcon {
  vertical-align: baseline;
  margin-right: 0.3em;
  margin-bottom: 0.2em;
  width: 18px;
  height: 18px;
}

.loginPanelBody {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  display: flex;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  background: #f4fffa url("../../../assets/images/winter-bg.jpg") no-repeat center / cover;
}

.loginFormRoot {
  position: inherit;
  max-width: 500px;
}

@media screen and (max-width: 615px) {
  .root {
    height: 100%;
  }
  .loginFormRoot {
    height: 100%;
  }
  .loginPanelBody {
    height: 100%;
  }
  .loginPanel {
    border-radius: 0 !important;
    border: none !important;
    height: 100% !important;
  }
}

.signUpContainer {
  scrollbar-width: 0;
}

.signupFormRoot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  margin-top: 0;
}

.subText {
  font-weight: 700 !important;
}

.signupLogo {
  text-align: center;
}

.loginPanel {
  padding: 32px;
  background: white;
  border-radius: 0.4rem;
  box-shadow: 0 0px 10px 2px rgb(167 167 167 / 45%);
  border: 2px solid #009654;
}

.loginHeader {
  margin-bottom: 32px;
}

.signUpPanel {
  padding: 32px;
  background: #fafafa;
  text-align: center;
}

.userGreeting {
  margin-top: 50px !important;
  font-weight: bold;
}

.arrowDownBody {
  margin-top: 30px !important;
}

.arrowDown {
  fill: #009654 !important;
  font-size: 2.5rem !important;
}

.signUpContent {
  margin-top: 60px !important;
}

.link {
  color: #000 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.messengerBtn {
  display: inline !important;
  text-align: center !important;
  margin-left: 80px !important;
}

.spinner {
  position: absolute;
  left: 49%;
}
