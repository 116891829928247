.total {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.toolTipContainer {
  position: relative;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #dedede;
  background-color: #fff;
  width: 141px;
  height: 80px;
}
.toolTip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.toolTipValue {
  font-size: 12px;
  font-weight: bold;
  font-family: "Poppins";
  line-height: 1.5;
}
.toolTipDate {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  color: #b8b8b8;
}
.toolTipTriangle {
  width: inherit;
  position: relative;
}
.toolTipTriangle:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid white;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: calc(100% - 46px);
  /* -webkit-filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.5)); */
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.2));
}
