.hiddenFileInput {
  display: none;
}

.tableWrap {
  overflow-x: auto;
  display: inline-block;
}

.tableWrap .okhati-list-header {
  padding-right: 4px;
  box-sizing: border-box;
}

.tableWrap .okhati-list-header > .okhati-list-headercell {
  margin: 8px 0 0 0;
  width: 200px !important;
}

.tableWrap .okhati-list-headercell:first-child,
.tableWrap .okhati-list-headercell:nth-child(2) {
  flex-basis: 200px !important;
  margin-left: 0px;
  flex-grow: unset !important;
}

.tableWrap .okhati-list-rowcell {
  flex-basis: 200px !important;
  min-width: 200px;
  margin: 0px;
  flex-grow: unset !important;
}
