.stockTransactionsList {
  margin-top: 0;
  height: calc(100vh - 100px);
  .subHeadContainer {
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      .searchContainer {
        padding-right: 1rem;
      }
    }
  }
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 140px;
          flex-grow: 1;
        }

        &:nth-child(2) {
          flex-basis: 140px;
          max-width: 300px;
          flex-grow: 1;
        }

        &:nth-child(3) {
          min-width: 150px;
          max-width: 200px;
        }

        &:nth-child(4) {
          flex-basis: 100px;
        }

        &:nth-child(5) {
          flex-basis: 140px;
        }

        &:nth-child(6) {
          & > span {
            max-width: 150px;
            width: 100%;
            text-align: right;
            padding-right: 15px;
          }
        }
      }
    }

    .okhati-list-row {
      padding-right: 20px;
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 140px;
          flex-grow: 1;
        }

        &:nth-child(2) {
          flex-basis: 140px;
          max-width: 300px;
          flex-grow: 1;
        }

        &:nth-child(3) {
          min-width: 150px;
          max-width: 200px;
        }

        &:nth-child(4) {
          flex-basis: 100px;
        }

        &:nth-child(5) {
          flex-basis: 140px;
        }
        &:nth-child(6) {
          & p {
            max-width: 150px;
            width: 100%;
            text-align: right;
            padding-right: 15px;
          }
        }
      }
    }
  }
}
