.appBarTabs {
  box-shadow: none !important;
}

.listViewHeight {
  height: calc(100vh - 222px);
}

.listTab {
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        justify-content: flex-start !important;
        &:first-child {
          flex-basis: 100px !important;
          flex-grow: 0.12 !important;
        }
        &:nth-child(2) {
          flex-basis: 100px !important;
          flex-grow: 0.19 !important;
        }
        &:nth-child(3) {
          flex-basis: 120px !important;
          flex-grow: 0.19 !important;
        }
        &:nth-child(4) {
          flex-basis: 120px !important;
          flex-grow: 0.2 !important;
        }
        &:nth-child(5) {
          flex-basis: 120px !important;
          flex-grow: 0.23 !important;
        }
        &:nth-child(6) {
          flex-basis: 90px !important;
          flex-grow: 0.1 !important;
        }
      }
    }
  }
}

.listTab {
  .okhati-list-container {
    .okhati-list-rowcell {
      justify-content: flex-start !important;
      &:first-child {
        flex-basis: 100px !important;
        flex-grow: 0.12 !important;
      }
      &:nth-child(2) {
        flex-basis: 100px !important;
        flex-grow: 0.19 !important;
      }
      &:nth-child(3) {
        flex-basis: 120px !important;
        flex-grow: 0.19 !important;
      }
      &:nth-child(4) {
        flex-basis: 120px !important;
        flex-grow: 0.2 !important;
      }
      &:nth-child(5) {
        flex-basis: 120px !important;
        flex-grow: 0.2 !important;
      }
      &:nth-child(6) {
        flex-basis: 100px !important;
        justify-content: flex-start !important;
      }
    }
  }
}

.navigatePage {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
  justify-content: flex-end;
  width: calc(100vw - 50px);
  position: fixed;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 960px) {
  .navigatePage {
    width: 100%;
  }
}
