.marginTop24 {
  margin-top: 24px;
}

.marginBotton24 {
  margin-bottom: 24px;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
