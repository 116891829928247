.okhati-timeline {
  &-event {
    min-height: 30px;
    display: flex;

    .okhati-timeline-indicator {
      flex-basis: 10px;

      >.okhati-timeline-dot {
        border-radius: 50%;

        &.dot-small {
          height: 8px;
          width: 8px;
          margin: 2px;
        }

        &.dot-big {
          height: 12px;
          width: 12px;
        }

        &.dot-green {
          background: #6BC733;
        }

        &.dot-teal {
          background: #00B4C2;
        }

        &.dot-red {
          background: #EB5B3F;
        }

        &.dot-gray {
          background: #889492;
        }

        &.dot-blue {
          background: #4570B7;
        }

        &.dot-orange {
          background: #EB5B3F;
        }
      }

      >.okhati-timeline-line {
        margin-left: 5.5px;
        margin-top: 3px;
        margin-bottom: 3px;
        border-left: 1px dashed #DDDFDF;
        height: calc(100% - 17px);
      }
    }

    .okhati-timeline-content {
      flex-grow: 1;
    }

    &:last-child {
      .okhati-timeline-line {
        display: none;
      }
    }
  }

  &-showmore {
    color: #00B4C2;
    text-align: left;
    z-index: 1000;
    padding-top: 10px;
    position: relative;
    cursor: pointer;
    top: -10px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 29%, rgba(255, 255, 255, 1) 72%);
  }
}